import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { GoPrimitiveDot } from 'react-icons/go'



const ValuesOne = ({image}) => {
    const { rpdata } = useContext(GlobalDataContext)

    const WhyChooseUS = [
        {
            desc: "Experience: With over 13 years of experience, we bring a wealth of knowledge and expertise to every project."
        },
        {
            desc: "Quality: We uphold a commitment to delivering high-quality results that meet and exceed our clients' expectations."
        },
        {
            desc: "Customer Focus: Your satisfaction is our priority. We work closely with you, ensuring your vision guides our work."
        },
        {
            desc: "Local Understanding: As a company based in Austin, Texas, we understand local needs, regulations, and standards."
        },
        {
            desc: "On-Time and On-Budget: We remain dedicated to completing projects within the agreed timeframe and budget, without compromising on quality."
        },

    ]
    return (
        <div
            className='py-[150px] bg-cover bg-no-repeat bg-fixed bg-center relative before:bg-zinc-900/50 before:absolute before:w-full before:h-full before:top-0'
            style={{ backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")` }}
        >
            <div className='relative w-4/5 mx-auto flex justify-end'>
                <div className='bg-white w-full lg:w-1/2 border-l-[15px] borderColor px-5 py-10 rounded-md'>
                    {
                        rpdata?.dbValues?.slice(0,2).map((items, index) => {
                            return (
                                <div key={index} className='pb-5'>
                                    <h4 className='flex items-center'>
                                        <GoPrimitiveDot className='dotsColor' fontSize={35} />
                                        <span>{items.title} </span>
                                    </h4>
                                    <p className='pl-9'>{items.description}</p>
                                </div>
                            )
                        })
                    }
                            <h4 className='flex items-center pb-2'>
                            <GoPrimitiveDot className='dotsColor' fontSize={35} />
                                <span>{ rpdata?.dbValues?.[2].title}</span>
                            </h4>

                    {
                        WhyChooseUS.map((item, index) => {
                            return(
                                
                                <div key={index} className='pb-5'>
                                    <li className='pl-9'>{item.desc}</li>
                            </div> 
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ValuesOne